import React from "react"

const initialState = {
  from: 'LT',
  date: '',
};

export const GlobalStateContext = React.createContext(initialState);
export const GlobalDispatchContext = React.createContext();

function reducer(state, action) {

  switch (action.type) {
    case "SET_DATES": {
      return {
        ...state,
        from: state.from,
        date: state.date,
      }
    }
    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
};

export default GlobalContextProvider